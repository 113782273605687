.modButton {
    background-color: unset !important;
    color: black !important;
    font-size: small !important;
    border: unset !important;
    display: flex !important;
    /* Centra l'icona all'interno del pulsante */
    align-items: center !important;
    justify-content: center !important;
}

.titleCard {
    font-size: large;
    font-weight: bold;

}

.subTitleCard {
    font-size: medium;
    font-weight: bold;
    margin-bottom: 2%;

}

.cardModifiche {
    margin-top: 2%;
    margin-bottom: 2%;

}

.formMail {
    width: 80% !important;
    margin-bottom: 2%;
    margin-top: 2%;
    height: 10%;
    font-size: small !important;
}

.formEditConfig {
    width: 30% !important;
    height: 10%;
    font-size: small !important;
}

.deleteButton {
    margin-left: 4%;
    font-size: x-small !important;
}

.addButton {
    font-size: small !important;
    margin-bottom: 4%;
}

.infoParametro {
    margin-top: 20% !important;
    margin-bottom: 2% !important;
    font-size: small;
    font-weight: bolder !important;
}

.switchButton {
    display: inline;

}

.cancelButton {
    font-size: small !important;
    float: right;
    margin-right: 1%;
}

.confirmButton {
    font-size: small !important;
    float: right !important;
    background-color: green !important;
    border: unset !important;
    color: black !important;
}

.formNumber {
    width: 30% !important;
}
.formEditUserMod{
   
    height: 10%;
    font-size: small !important;
}