@font-face {
  font-family: 'Montserrat';
  src: url('./components/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./components/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./components/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 'semiBold';
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./components/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: bolder;
  font-style: normal;
}


/*prima non era body ma  *    */
body {
  margin: 0;
  padding: 0;
  text-decoration: none;
}


.login-text {
  font-family: 'Montserrat';
  font-weight: bold;
  text-align: center;
  font-size: large;
  margin-top: 2%;
  text-transform: uppercase;
}
.amplify-button[data-variation='primary'] {
  background: #003057;
}

/*
.container-2 {
  display: flex;
  flex-grow: 1;
  margin-left: 0;
  padding: 0;
  overflow: hidden;

}*/
/*
.container_new {

  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}*/

main {
  width: 100%;
  padding: 20px;
  flex-grow: 1;
  overflow-y: auto;
  /* Abilita lo scrolling verticale nel caso in cui il contenuto sia più lungo dell'altezza disponibile */
}
/*
.profileButton .dropdown-toggle {
  background-color: #003057 !important;
  border-color: #003057 !important;

}
.profileButton .dropdown-item:hover {
  background-color: #ebebeb; /* Colore di sfondo personalizzato dell'elemento del menu dropdown al passaggio del mouse */
  /*color: #555; /* Colore del testo personalizzato dell'elemento del menu dropdown al passaggio del mouse */
  /*
}*/

/*
.sidebar {
  background: #003057;
  color: #fff;
  height: auto;
  transition: all 0.5s;
  overflow: hidden;

}*/
/*
.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}
*/
/*
.logo {
  font-size: 30px;

}*/

/*
.menu_bar {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}*/
/*
.link {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
}

.link:hover {
  background: lightblue;
  color: #000;
  transition: all 0.5s;

}

.active {
  background: white;
  color: #000;

}

.icon,
.link_text {
  font-size: 20px;


}*/


/*
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #fff;
  border-bottom: 1px solid #003057;
}*/
/*
.laiotech {
  margin-left: 5vh;
  height: 50px;

}*/

/*
.card_img_top {
  height: 100px;
  margin-top: 5%;


}

.card {
  margin-left: 1rem !important;
  margin-bottom: 1rem;
  border-color: #003057 !important;
}

.card-body {
  padding: 1rem !important;

}

.card:hover {
  box-shadow: 5px 10px 20px 1px #003057 !important;
}

.second-button-card {
  margin-left: 10px !important;
}

.iconStatus {
  margin-right: 20px !important;
  margin-top: 20px;

}

.imageMachineDetail {
  height: 45vh !important;
  max-width: 25%;
  margin-left: 10vw;
  margin-top: 5vh;
  margin-right: 5vw;

}

.cardMachineDetail {
  box-shadow: 5px 10px 15px 1px #003057 !important;
}*/

.dropdown .dropdown-menu {
  position: absolute;
  overflow-y: auto !important;

  max-height: 40vh !important;
}


.mainCard {
  display: flex;
  width: 100%;
  height: fit-content;

  flex-grow: 4;
  /*overflow-y: auto !important;*/
  /*overflow-x: clip;*/
  border: unset !important;
  padding: 0;

  font-family: 'Montserrat', sans-serif;
  font-size: small;

}