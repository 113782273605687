@font-face {
    font-family: 'MontserratBold';
    src: url('../components/fonts/Montserrat-Bold.ttf') format('truetype');

}

.languageButton .dropdown-toggle {
    background-color: unset !important;
    border-color: unset !important;

    display: flex !important;
    /* Centra l'icona all'interno del pulsante */
    align-items: center !important;
    justify-content: center !important;
}

.profileButton .dropdown-toggle {
    /*background-color: #003057 !important;
    border-color: #003057 !important;*/

    color: #000;
    font-family: 'MontserratBold';

    /*padding: 20% !important;*/
    /*margin-top: 5px ;*/
    /*margin: auto !important;*/
    background-color: unset !important;
    /*border: unset !important;*/

    aspect-ratio: 1 / 1 !important;
    border-radius: 100% !important;
    border-color: black !important;
    /*background-color: none !important;
    border-color: black !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px !important;*/
    display: flex !important;
    /* Centra l'icona all'interno del pulsante */
    align-items: center !important;
    justify-content: center !important;


}

.profileButton .dropdown-toggle:hover {
    color: #000 !important;
    font-family: 'MontserratBold';
}

.profileButton .dropdown-item:hover {
    background-color: #ebebeb;
    /* Colore di sfondo personalizzato dell'elemento del menu dropdown al passaggio del mouse */
    color: #555;
    /* Colore del testo personalizzato dell'elemento del menu dropdown al passaggio del mouse */
}

.profileButton .dropdown-toggle::after {
    display: none;
    color: #000 !important;
    font-family: 'MontserratBold';
}



.sidebar {
    background: #003057;
    color: #fff;
    height: 100%;
    transition: all 0.6s;
    overflow: hidden;

}

.top_section {
    display: flex;
    align-items: center;
    padding: 20px 15px;
}

.logo {
    width: 8vw;
    font-size: 30px;

}

.container_new {

    /*height: 100%;*/

    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    margin: 0;
    padding: 0;
}



.link {
    display: flex;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
    text-decoration: none;
}

.link:hover {
    background: lightblue;
    color: #000;
    transition: all 0.5s;

}

.active {
    background: white;
    color: #000;
    font-family: 'Montserrat';

}

.icon,
.link_text {
    font-size: normal;


}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #fff;
    /* border-bottom: 1px solid #003057;*/
    
}

.laiotech {
    margin-left: 5vh;
    height: 45px;

}

.main {
    display: flex;
    width: 100%;
    height: 100%;
    /*max-height: 98vh;*/

    /*max-height: 100vh;*/
    padding: 20px;
    /*flex-grow: 1;*/
    justify-content: center;
    overflow-y: auto !important;
    /* Abilita lo scrolling verticale nel caso in cui il contenuto sia più lungo dell'altezza disponibile */
}

.dark-mode {
    background-color: #333;
    color: white;
}


.container-fluid{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}